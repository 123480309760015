// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const oepdev = {
  //aws_app_analytics: 'disable',
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "e58lars96g",
      name: "JS-PLATFORM",
      description: "",
      endpoint:
        "https://p0i3rhpbr3.execute-api.us-east-1.amazonaws.com/JS519381",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "e58lars96g",
      name: "JS-PLATFORM",
      description: "",
      endpoint:
        "https://e58lars96g.execute-api.us-east-1.amazonaws.com/JS519381",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "JS-PLATFORM",
  aws_cloud_logic_custom_name_E: "JS-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:7ed6b066-f5b3-44d6-b929-020eeda966b5",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "jssaher-resources",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1hfr1iyugj21x.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "jssaher-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_Md1L0swbI",
  aws_user_pools_web_client_id: "4ap10lapjo6grmkvvl3rsotmd2",
  aws_cloud_logic_custom_endpoint:
    "https://e58lars96g.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHER",
  aws_org_id_E: "JSSAHER",
  aws_user_pools_id_E: "us-east-1_Md1L0swbI",
  aws_user_pools_web_client_id_E: "4ap10lapjo6grmkvvl3rsotmd2",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d1hfr1iyugj21x.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:7ed6b066-f5b3-44d6-b929-020eeda966b5",
  aws_cloud_logic_custom_endpoint_E:
    "https://e58lars96g.execute-api.us-east-1.amazonaws.com/JS519381",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "e58lars96g",
      name: "JS-PLATFORM",
      description: "",
      endpoint:
        "https://e58lars96g.execute-api.us-east-1.amazonaws.com/JS519381",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};

const oepProd = {
  //aws_app_analytics: 'disable',
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "0g4d0icu51",
      name: "JSS-PLATFORM",
      description: "",
      endpoint:
        "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/JSSAHER-Prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "0g4d0icu51",
      name: "JSS-PLATFORM",
      description: "",
      endpoint:
        "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/JSSAHER-Prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "JSS-PLATFORM",
  aws_cloud_logic_custom_name_E: "JSS-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:053fb1d7-4265-4cc7-bf1b-6ab65fcf96cc",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "jssaher-oep",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d3eyik1plsgzx.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "jssaher-oep",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_1h3qHgBrx",
  aws_user_pools_web_client_id: "6d0r4v6b0mkfl55kbc5q9fn4jt",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHER",
  aws_org_id_E: "JSSAHER",
  aws_user_pools_id_E: "us-east-1_1h3qHgBrx",
  aws_user_pools_web_client_id_E: "6d0r4v6b0mkfl55kbc5q9fn4jt",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d3eyik1plsgzx.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:053fb1d7-4265-4cc7-bf1b-6ab65fcf96cc",
  aws_cloud_logic_custom_endpoint_E:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/JSSAHER-Prod",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "0g4d0icu51",
      name: "JSS-PLATFORM",
      description: "",
      endpoint:
        "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/JSSAHER-Prod",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};

const qsd = {
  //aws_app_analytics: 'disable',
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "QSD-PLATFORM",
  aws_cloud_logic_custom_name_E: "QSD-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:9353bbc9-07fb-4f0c-98a8-8997413585bf",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "qsd-resources",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d3cb79yue9i8q3.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "qsd-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_2JYLYVl9h",
  aws_user_pools_web_client_id: "520ilpp3444pqmlstg9rc3b5nu",
  aws_cloud_logic_custom_endpoint:
    "https://e58lars96g.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "QSD",
  aws_org_id_E: "QSD",
  aws_user_pools_id_E: "us-east-1_2JYLYVl9h",
  aws_user_pools_web_client_id_E: "520ilpp3444pqmlstg9rc3b5nu",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d3cb79yue9i8q3.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:9353bbc9-07fb-4f0c-98a8-8997413585bf",
  aws_cloud_logic_custom_endpoint_E:
    "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};
const oepMauritius = {
  //aws_app_analytics: 'disable',
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "jmq6huxo7a",
      name: "OEPORG-PLATFORM",
      description: "",
      endpoint:
        " https://jmq6huxo7a.execute-api.us-east-1.amazonaws.com/OEPORG-PLATFORM",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "jmq6huxo7a",
      name: "OEPORG-PLATFORM",
      description: "",
      endpoint:
        "https://jmq6huxo7a.execute-api.us-east-1.amazonaws.com/OEPORG-PLATFORM",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "OEPORG-PLATFORM",
  aws_cloud_logic_custom_name_E: "OEPORG-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:52285fee-e238-450f-a1af-829712db4ebf",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-oepmauritius",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1fgflxnncw0ta.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "jssaher-oep",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_BOAaux2EE",
  aws_user_pools_web_client_id: "1u84onee73v45o3pntlsnl0hmn",
  aws_cloud_logic_custom_endpoint:
    "https://jmq6huxo7a.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "OEPMAURITIUS",
  aws_org_id_E: "OEPMAURITIUS",
  aws_user_pools_id_E: "us-east-1_BOAaux2EE",
  aws_user_pools_web_client_id_E: "1u84onee73v45o3pntlsnl0hmn",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d1fgflxnncw0ta.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:52285fee-e238-450f-a1af-829712db4ebf",
  aws_cloud_logic_custom_endpoint_E:
    "https://jmq6huxo7a.execute-api.us-east-1.amazonaws.com/OEPORG-PLATFORM",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "jmq6huxo7a",
      name: "OEPORG-PLATFORM",
      description: "",
      endpoint:
        " https://jmq6huxo7a.execute-api.us-east-1.amazonaws.com/OEPORG-PLATFORM",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};
export default oepdev;

import React from "react";
import "./Course.scss";
import { useSelector} from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

const ProjectFile = (props) => {
  let { handleFileClose, obj, objId, bcid } = props;

  let userDetails = useSelector(authData);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100% !important",
        height: "84vh",
        overflowX: "hidden",
        paddingBottom: "20px",
        background: "white",
      }}
    >
      <div>
        <div
          style={{
            margin: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          <p>{obj.fileName} </p>
          <button onClick={handleFileClose}>X</button>
        </div>

        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-data/data/project/${
            userDetails.eid
          }/${bcid}/${objId}/${obj.fileUrlName}`}
          width={"92vw"}
          title="Iframe Example"
          style={{ width: "92vw", height: "74vh", margin: "2rem" }}
        ></iframe>
      </div>
    </div>
  );
};

export default ProjectFile;
